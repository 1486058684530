import "./ContactForm.css";
import Card from "./Card";
import { Helmet } from "react-helmet";
import { useState } from "react";
import emailjs from "emailjs-com";
import FindOutMoreButton from "./FindOutMoreButton";
import PurpleBox from "./PurpleBox";

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [message, setMessage] = useState("");

  // Smooth scrolling function
  function scrollToSection(elementID) {
    const element = document.getElementById(elementID);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  // Attach click event listeners to the navigation links
  document.querySelectorAll('a[href^="#contact-text"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      const targetID = this.getAttribute("href").substring(1);
      scrollToSection(targetID);
    });
  });

  const handleSubmit = (e) => {
    // if()
    e.preventDefault();

    // EmailJS configuration
    const serviceId = "service_cb9iuq3";
    const templateId = "template_mnxa2vr";
    const userId = "oVwPtJHP_o6KtLxnE";

    // Sending the email
    emailjs
      .send(
        serviceId,
        templateId,
        {
          from_name: name,
          from_email: emailId,
          message: message,
        },
        userId
      )
      .then((response) => {
        console.log("Email sent!", response.status, response.text);
        // Handle success (e.g., show a success message, clear form fields)
        setFormSubmitted(!formSubmitted);
      })
      .catch((error) => {
        console.error("Email error:", error);
        // Handle error (e.g., show an error message)
      });
  };
  return (
    <div className="contactForm">
      <div>
        <div className="contactUsHeader">
          <h1 className="contactUsText" id="contact-text">
            Contact Us
          </h1>
          <div className="contactUsDesText">
            <p>
              Delegate Quality Assurance to us and we will guarantee bug free
              experience. Lets talk more on how we can help here. We're ready to
              empower you to do business on your terms.
            </p>
          </div>
        </div>
        <Helmet>
        <title>Contact Us | Qualaces</title>
        <meta
          name="description"
          content="Delegate Quality Assurance to us and we will guarantee bug free experience. Lets talk more on how we can help here. We're ready to empower you to do business on your terms."
        />
      </Helmet>

      <Helmet>
        <link rel="canonical" href="https://qualaces.com/contact-us" />
      </Helmet>
        <div className="contactUsBoxes">
          <div className="contactUsBox">
            <a>Calgary, Alberta</a>
          </div>
          <div className="contactUsBox">
            <a href="tel:+1-805-328-7007">+1 (587) 319-6658</a>
          </div>
          <div className="contactUsBox">
            <a href="mailto:info@qualaces.com">info@qualaces.com</a>
          </div>
        </div>
        <div className="contactFormSection">
          <div className="contactUsImageWrapper">
            <img src="./contactUs.png" className="contactUsImage" />
          </div>
          {formSubmitted || (
            <form className="contactFormContainer" onSubmit={handleSubmit}>
              <div>
                <p className="formLabelText">
                  We're here for <span className="discover-text">all</span>
                </p>
                <p className="formLabelText">your QA needs</p>
              </div>

              <input
                placeholder="Your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              ></input>
              <input
                placeholder="Your email"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                required
                type="email" // Add type="email" for email validation
              ></input>
              <textarea
                placeholder="Message"
                rows="5"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
              <button className="sendButton" type="submit">
                Send Message
              </button>
            </form>
          )}
          {formSubmitted && (
            <div className="submissionSuccessContainer">
              <div>
                <p className="SuccessText">Thank you for</p>
                <p className="SuccessText">contacting us!</p>
              </div>
              <div>
                <p className="purple-text">We have received your message.</p>
                <p className="purple-text">We'll be in touch soon.</p>
              </div>
              <div className="contactUsDesText">
                <p>Let's Connect!</p>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/qualaces/"
                  className="footerlink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="./LinkedIn.png"
                    alt="LinkedIn"
                    className="icon-image"
                  />
                  <a
                    href="https://www.youtube.com/@qualaces"
                    className="footerlink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="./youtube.png"
                      alt="twitter"
                      className="icon-image"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/qualaces"
                    className="footerlink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="./facebook.png"
                      alt="twitter"
                      className="icon-image"
                    />
                  </a>
                  <a
                    href="https://www.Instagram.com/qualaces"
                    className="footerlink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="./Instagram.png"
                      alt="twitter"
                      className="icon-image"
                    />
                  </a>
                  <a
                    href="https://www.twitter.com/qualaces"
                    className="footerlink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="./twitter.png"
                      alt="twitter"
                      className="icon-image"
                    />
                  </a>
                </a>
              </div>
              <FindOutMoreButton />
            </div>
          )}
        </div>
      </div>
      <img src="./calgary_Map.png" className="calgary_map" />
      {/* <div> */}
      <PurpleBox />
      {/* </div> */}
    </div>
  );
};

export default ContactForm;
