import React from "react";
import "./Hero.css";
import Card from "./Card";

import HorizontalCard from "./HorizontalCard";
import PurpleBox from "./PurpleBox";
import FindOutMoreButton from "./FindOutMoreButton";

import { Helmet } from "react-helmet";

const Hero = () => {

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Qualaces - Software Testing Services",
    "description": "Unlock unparalleled efficiency in your QA process with Quality Aces. We provide cutting-edge software and quality testing solutions.",
    "url": "https://www.qualaces.com",
    "publisher": {
      "@type": "Organization",
      "name": "Qualaces",
      "url": "https://www.qualaces.com",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.qualaces.com/logo.png",
        "width": 200,
        "height": 50
      },
      "sameAs": [
        "https://www.linkedin.com/company/qualaces",
        "https://twitter.com/qualaces"
      ]
    },
    "mainEntity": {
      "@type": "Organization",
      "name": "Quality Aces",
      "description": "We provide exceptional software and quality testing services, including performance testing, security testing, usability testing, and more.",
      "url": "https://www.qualaces.com",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.qualaces.com/logo.png",
        "width": 200,
        "height": 50
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+1-800-555-1234",
        "contactType": "Customer Service",
        "areaServed": "Global",
        "availableLanguage": ["English"]
      }
    },
    "about": {
      "@type": "Thing",
      "name": "Software Testing Services",
      "description": "We specialize in QA and software testing solutions, including performance testing, compatibility testing, and security testing."
    },
    "hasPart": [
      {
        "@type": "Service",
        "name": "Performance Testing",
        "description": "Assess the scalability and responsiveness of your software.",
        "provider": {
          "@type": "Organization",
          "name": "Quality Aces"
        }
      },
      {
        "@type": "Service",
        "name": "Security Testing",
        "description": "Identify vulnerabilities in your software to ensure its security.",
        "provider": {
          "@type": "Organization",
          "name": "Quality Aces"
        }
      },
      {
        "@type": "Service",
        "name": "Usability Testing",
        "description": "Enhance the user experience of your software.",
        "provider": {
          "@type": "Organization",
          "name": "Quality Aces"
        }
      }
    ]
  };

  return (
    <div className="CareersBodyWrapper">
      {/* Injected schema markup */}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <Helmet>
        <title>Qualaces | Software Testing Solutions</title>
        <meta
          name="description"
          content="Join us for an immersive collaboration with our top-notch experts, where you'll benefit personalized attention at a cost-effective rate."
        />
      </Helmet>

      <Helmet>
        <link rel="canonical" href="https://qualaces.com" />
      </Helmet>

      <div className="hero">
        <div className="heroLeftContainer">
          <div className="heroText">
            <h1>Unlock</h1>
            <h1>unparalleled</h1>
            <h1>efficiency in</h1>
            <h1>your QA</h1>
            <h1>process with</h1>
            <p>
              <span className="qualityAces">Quality Aces</span>.
            </p>
          </div>
          <div className="hero-description">
            <p>Join us for an immersive collaboration with</p>
            <p>our top-notch experts, where you'll benefit</p>
            <p>personalized attention at a cost-effective rate,</p>
            <p> all while we uphold our commitment to quality.</p>
          </div>
          <div>
            <FindOutMoreButton />
          </div>
        </div>
        <div className="hero-image-container">
          <img src="./hero.png" alt="Illustration of a QA tester inspecting a checklist with a magnifying glass, surrounded by boxes and gears, representing quality assurance testing at Qualaces." className="hero-image"></img>
        </div>
      </div>
      <div className="hero3">
        <div>
          <h2 className="discover-text">ABOUT US</h2>
          <p className="whychooseus-text">
            Why <span className="discover-text">choose</span> us?
          </p>
          <div className="secondHeroTextContainer">
            <p>Elevate your software's performance and</p>
            <p>quality with our exceptional software and</p>
            <p>quality testing services. Qualaces</p>
            <p>designs and delivers cutting-edge QA</p>
            <p>and software testing solutions.</p>
          </div>
        </div>
        <div className="rightContainer">
          <Card
            title={"Commitment to Quality"}
            description={
              "Our testing methodologies and attention to detail guarantee that your software is thoroughly examined and validated."
            }
          />
          <Card
            title={"Our Expertise"}
            description={
              "Each member has a strong background in software testing methodologies, quality assurance, and industry best practices."
            }
          />
          <Card
            title={"Personalized Attention"}
            description={
              "Our team will work closely with you, providing clear communication, understanding your requirements, and delivering solutions."
            }
          />
          <Card
            title={"Integrity and Confidentiality"}
            description={
              "We operate in accordance with utmost moral and professional standards."
            }
          />
        </div>
      </div>
      <div className="herothird">
        <div className="leftheroContainer">
          <img src="./heroimagee.png" alt = "Illustration of a QA tester sitting among documents and a laptop, holding a light bulb, symbolizing innovative ideas in software quality assurance at Qualaces." className="herothreeimage" />
        </div>
        <div className="rightheroContainer">
          <div>
            <h2 className="discover-text">WHAT WE DO</h2>
            <p className="whychooseus-text">Our Services</p>
          </div>
          <div className="third-hero-description">
            <p>
              As a software and quality testing services company, we provide a
              wide range of services to ensure the success of your software. Our
              offerings include performance testing to assess its scalability
              and responsiveness, compatibility testing across various platforms
              and devices, security testing to identify vulnerabilities,
              usability testing to enhance the user experience, mobile app
              testing, strategic consulting for test planning and optimization,
              and detailed test management and reporting.
            </p>
          </div>
          <FindOutMoreButton />
        </div>
      </div>
      <div className="hero4Wrapper">
        <div className="hero4">
          <p>Forerunners in</p>
          <span className="bluefonts">Software Testing</span>
        </div>
      </div>
      <div className="mainContainer">
        <div>
          <h2 className="discover-text">DISCOVER</h2>
          <p className="header-text">Main Types of Testing</p>
        </div>
        <div className="cardWrapper">
          <HorizontalCard
            title={"Smoke Testing"}
            description={
              "Smoke testing is done to ensure that the most critical functionalities of a system are working fine."
            }
          />
          <HorizontalCard
            title={"Sanity Testing"}
            description={
              "Sanity testing is a type of software testing done after a minor change in the code or functionality to ascertain that the bugs have been fixed."
            }
          />
          <HorizontalCard
            title={"Regression Testing"}
            description={
              "Regression testing is exhaustive testing that is conducted when changes or enhancements are made to the software."
            }
          />
          <HorizontalCard
            title={"Ticket Testing"}
            description={
              "Involves testing individual items or 'tickets' that are part of an issue tracking system."
            }
          />
        </div>
        <div>
          <FindOutMoreButton />
        </div>
        <PurpleBox />
      </div>
    </div>
  );
};

export default Hero;
